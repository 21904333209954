const { REACT_APP_OWNER } = process.env;


export interface TokenSymbol {
    chainId: string;
    name: string;
    queryPriceName: string;
    logoURI: string;
    symbol: string;
    address: string | `0x${string}`;
    decimals: number;
}

export interface ContractProps {
    contractAddress: `0x${string}`;
    cTokenName?: string;
    tokenName?: string;
    tokenAddress: string;
    isNativeToken: boolean;
    collateralable: boolean;
    priceId: string;
    mockPriceData?: string;
    icon?: string;
    decimal?: number;
}


type CONS = {
    networkName?: string;
    symbol?: string;
    rpcUrl: string;
    scanUrl?: string;
    chainId: string;
    projectId: string;
    PlanetContractAddress: string;
    SpaceControlContractAddress: string;
    INodeContractAddress: string;
    referralRewardContractAddress: string;
}
const CONSTANTSMAP: { [props: string]: CONS } = {
    'dev': {
        networkName: 'OORT-Mainnet',
        rpcUrl: 'https://mainnet-rpc.oortech.com',
        chainId: `0x${Number(9700).toString(16)}`,
        symbol: 'oort',
        projectId: 'b81386c2a689b2d675f917e574aa8572',
        PlanetContractAddress: '0x245FaC02cE791b223Fe4cD967E9Dc86D21D60b3d',
        SpaceControlContractAddress: '0x591a9FFB900257c8b06A4169bDf110ccf8F4449B',
        INodeContractAddress: '0xDE155823964816d6E67de8eA31DEf95D59aaE2Fb',
        referralRewardContractAddress: '0x3b16E13F8A0bc5D2B9C3657154A32A7Da3c7c42d',

    },
    'prod': {
        networkName: 'OORT-Mainnet',
        rpcUrl: 'https://mainnet-rpc.oortech.com',
        chainId: `0x${Number(9700).toString(16)}`,
        symbol: 'oort',
        projectId: 'b81386c2a689b2d675f917e574aa8572',
        PlanetContractAddress: '0xf7A06aa9608a07b0778c805d87387A8d2F0D6280',
        SpaceControlContractAddress: '0x96bC391ba61e2F86CB68FEeD681a5c0B1176F210',
        INodeContractAddress: '0xDE155823964816d6E67de8eA31DEf95D59aaE2Fb',
        referralRewardContractAddress: '0x9fE0f230a68E81c77FB2338e74C293028414f70A',
    },
}

export const CONSTANTS = CONSTANTSMAP[REACT_APP_OWNER || 'dev']
