
import './index.scss'
import { CONSTANTS } from '../../models';
import { Web3Context, useIntl } from '../../context';
import { useContext, useEffect, useMemo, useState } from 'react';
import bignumber from 'bignumber.js';
import NumberText from '../../components/NumberText';
import { CircularProgress, Drawer } from '@mui/material';
import { InputNumber } from '../../components';
import { NavLink } from 'react-router-dom';
import { ContractTransactionReceipt, ContractTransactionResponse, MaxInt256, MaxUint256, TransactionResponse } from 'ethers';
import { NULLVAULT } from '../../utils';
import CreateVaultDrawer from '../../components/CreateVaultDrawer';
const BorrowPage = () => {
    const { intl } = useIntl();
    const { spaceControlContract, planetContract, setLoadingTX, refreshAccountInfo, refreshPoolInfo, walletBalance, userinfo, poolinfo, account, readonlySpaceControlContract, } = useContext(Web3Context);
    const [openStart, setOpenStart] = useState(false)
    const [openWithdraw, setOpenWithdraw] = useState(false)
    const [openRepay, setOpenRepay] = useState(false)
    const [amount, setAmount] = useState('')
    const [max, setMax] = useState(false);
    const [openBorrow, setOpenBorrow] = useState(false)
    const canBorrowLeft = useMemo(() => {
        if (userinfo) {
            return bignumber(userinfo.mycredit).minus(bignumber(userinfo.borrowBalance)).toString()
        }
        return '0'
    }, [userinfo])
    const amountMax = useMemo(() => {
        if (userinfo && poolinfo) {
            if (openBorrow) {
                return bignumber(canBorrowLeft).gt(bignumber(poolinfo.maxBorrowAmount)) ? poolinfo.maxBorrowAmount : bignumber(canBorrowLeft);
            }
            if (openRepay) {
                return bignumber(walletBalance).gt(bignumber(userinfo.borrowBalance)) ? userinfo.borrowBalance : walletBalance
            }
        }
        return '0'
    }, [openBorrow, canBorrowLeft, openRepay, userinfo, poolinfo])

    const withdrawReapyAmount = useMemo(() => {
        if (userinfo) {
            return [
                bignumber(userinfo.vaultAvailableBalance).multipliedBy(bignumber(userinfo.autoSplitRateMantiss).div(1e18)).toString(),
                bignumber(userinfo.vaultAvailableBalance).multipliedBy(bignumber(userinfo.autoTransferRateMantiss).div(1e18)).toString(),
            ]
        }
        return ['0', '0']
    }, [userinfo])
    const checkDisabled = useMemo(() => {
        if (!bignumber(amount).gt(0)) {
            return true
        }
        if (openBorrow && userinfo && poolinfo) {
            const left = bignumber(userinfo.mycredit).minus(bignumber(userinfo.borrowBalance))
            const min = left.gt(bignumber(poolinfo.maxBorrowAmount)) ? poolinfo.maxBorrowAmount : left
            if (bignumber(amount).multipliedBy(1e18).gt(min)) {
                return true
            }
        }
        if (openRepay && userinfo && poolinfo) {
            const min = bignumber(walletBalance).gt(bignumber(userinfo.borrowBalance)) ? userinfo.borrowBalance : walletBalance

            if (bignumber(amount).multipliedBy(1e18).gt(min)) {
                return true
            }
        }
    }, [amount, openBorrow, openRepay, walletBalance, userinfo, poolinfo])

    const handleBorrow = async () => {
        try {
            setLoadingTX('pending');
            let tx: TransactionResponse;
            if (max) {
                tx = await planetContract.borrow(MaxUint256.toString())
            } else {
                tx = await planetContract.borrow(bignumber(amount).multipliedBy(`1e+${18}`).toFormat(0))
            }
            await tx.wait();
            setLoadingTX('successful')
            refreshAccountInfo();
            refreshPoolInfo();
            reset()
        } catch (error: any) {
            console.error('error', error);
            if (error.message && ((error.message as string).includes('user rejected action') || (error.message as string).includes('Cancel'))) {
                setLoadingTX('cancel')
            } else {
                setLoadingTX('failed')
            }
            reset()
        }
    }
    const handleWithdrawReapy = async () => {
        try {
            setLoadingTX('pending');
            const tx: TransactionResponse = await spaceControlContract.harvest(account)
            await tx.wait();
            setLoadingTX('successful')
            refreshAccountInfo();
            refreshPoolInfo();
            reset()
        } catch (error: any) {
            console.error('error', error);
            if (error.message && ((error.message as string).includes('user rejected action') || (error.message as string).includes('Cancel'))) {
                setLoadingTX('cancel')
            } else {
                setLoadingTX('failed')
            }
            reset()
        }
    }

    const handleReapy = async () => {
        try {
            setLoadingTX('pending');
            let tx: TransactionResponse
            if (max) {
                const borrowBalanceCurrent: bigint = await planetContract.borrowBalanceCurrent.staticCall(account);
                console.log('max', bignumber(borrowBalanceCurrent.toString()).div(0.9999).toFormat(0));
                tx = await planetContract.repayBorrow('0', { value: bignumber(borrowBalanceCurrent.toString()).div(0.9999).toFormat(0) })
            } else {
                console.log('borrow ', bignumber(amount).multipliedBy(`1e+${18}`).div(0.9999).toFormat(0));
                tx = await planetContract.repayBorrow(bignumber(amount).multipliedBy(`1e+${18}`).toFormat(0), { value: bignumber(amount).multipliedBy(`1e+${18}`).div(0.9999).toFormat(0) })
            }
            await tx.wait();
            setLoadingTX('successful')
            refreshAccountInfo();
            refreshPoolInfo();
            reset()
        } catch (error: any) {
            console.error('error', error);
            if (error.message && ((error.message as string).includes('user rejected action') || (error.message as string).includes('Cancel'))) {
                setLoadingTX('cancel')
            } else {
                setLoadingTX('failed')
            }
            reset()
        }
    }
    const reset = () => {
        setAmount('');
        setMax(false);
        setOpenStart(false);
        setOpenBorrow(false);
        setOpenWithdraw(false)
        setOpenRepay(false);
    }
    return <div className='borrow-page'>
        {userinfo && poolinfo ? <>
            {userinfo.vault != NULLVAULT && <>
                <div className='card'>
                    <div className='flex'>
                        <div className='card-info'>
                            <div className='title'>{intl.Borrow.MyCredit}</div>
                            <div className='flex justify-between value'>
                                <NumberText value={userinfo.mycredit} valueDecimals={18} />
                                <span className='unit'>OORT</span>
                            </div>
                        </div>
                        <div className='card-info'>
                            <div className='title'>{intl.Borrow.CollateralDevices}</div>
                            <div className='flex value'>
                                <span>
                                    {userinfo.vaultNodelist?.length} / 64
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <NavLink to={'/mydevices'}>
                            <button className='bg-white'>{intl.Borrow.ManageDevice}</button>
                        </NavLink>
                    </div>
                </div>
                <div className='card'>
                    <div className='flex'>
                        <div className='card-info'>
                            <div className='title'>{intl.Borrow.MyDebt}</div>
                            <div className='flex justify-between value'>
                                <NumberText value={userinfo.borrowBalance} valueDecimals={18} />
                                <span className='unit'>OORT</span>
                            </div>
                        </div>
                        <div className='card-info'>
                            <div className='title'>{intl.Borrow.CollateralDevicesBalance}</div>
                            <div className='flex justify-between value'>
                                <NumberText value={userinfo.vaultAvailableBalance} valueDecimals={18} displayDecimals={4} />
                                <span className='unit'>OORT</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingInline: '12px' }} className='flex justify-between'>
                        <button disabled={!bignumber(userinfo.mycredit).gt(0)} className='bg-white' onClick={() => {
                            setOpenBorrow(true)
                        }}>{intl.Header.Borrow}</button>
                        <button className='bg-white' disabled={bignumber(userinfo.borrowBalance).eq(0) || bignumber(userinfo.vaultAvailableBalance).eq(0)} onClick={() => setOpenWithdraw(true)}>{intl.Borrow.WithdrawRepay}</button>
                    </div>
                </div>
                <div className='card'>
                    <div className='flex'>
                        <div className='card-info'>
                            <div className='title'>{intl.Borrow.WalletAvailableBalance} </div>
                            <div className='flex value'>
                                <NumberText value={walletBalance} valueDecimals={18} />
                                <span className='unit'>OORT</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingInline: '12px' }} className='flex'>
                        <button disabled={bignumber(userinfo.borrowBalance).eq(0)} className='bg-white' onClick={() => setOpenRepay(true)}>{intl.Borrow.Repay}</button>
                    </div>
                </div>
            </>}
            {userinfo.vault == NULLVAULT && <>
                <div className='desc-wrap'>
                    <div className='desc'>
                        {intl.Borrow.BorrowText1}
                    </div>
                    <div className='desc'>
                        {intl.Borrow.BorrowText2}
                    </div>
                </div>
                <div className='btn-group'>
                    <button className='bg-primary full' onClick={() => setOpenStart(true)} >{intl.Borrow.StartBorrow}</button>
                </div>
            </>}</>
            : <div style={{ marginTop: '24px', textAlign: 'center' }}>
                <CircularProgress size={60} />
            </div>}
        <CreateVaultDrawer open={openStart} onClose={reset} /> 
        <Drawer anchor='bottom' open={openBorrow} onClose={reset}>
            <div className='title'>{intl.Header.Borrow}</div>
            <div className='apy flex justify-between'>
                <div>{intl.Header.Borrow}</div>
                {userinfo && <div>
                    <NumberText valueDecimals={18} value={userinfo.mycredit} />
                    <span className='unit'>OORT</span>
                </div>}
            </div>
            <div className='apy flex justify-between'>
                <div>{intl.Borrow.MyDebt}</div>
                {userinfo && <div>
                    <NumberText valueDecimals={18} value={userinfo.borrowBalance} />
                    <span className='unit'>OORT</span>
                </div>}
            </div>
            <div className='apy flex justify-between'>
                <div>{intl.Dashboard.BorrowingAPY}</div>
                {poolinfo && <div>
                    <NumberText valueDecimals={16} value={poolinfo.borrowAPY} />
                    <span >%</span>
                </div>}
            </div>
            <div className='balance'>
                {intl.Borrow.BorrowText}
            </div>
            <div className='input-container'>
                <InputNumber decimal={18} value={amount}
                    onChange={(e) => {
                        setAmount(e.target.value)
                        setMax(false)
                    }}
                    onMax={() => {
                        setMax(true)
                        setAmount(bignumber(amountMax).dividedBy(`1e+${18}`).toFixed(18))
                    }}
                />
            </div>
            <div className='balance flex'>
                <span>{intl.Borrow.CanBorrow}</span>
                <NumberText style={{ color: 'var(--theme-color)', marginInline: '8px' }} valueDecimals={18} value={amountMax} />
                <span className='unit'>OORT</span>
            </div>
            <div className='action-group'>
                <button disabled={checkDisabled} className='bg-primary full' onClick={handleBorrow}>{intl.Borrow.Confirm}</button>
            </div>
        </Drawer>
        <Drawer anchor='bottom' open={openWithdraw} onClose={reset}>
            <div className='title'>{intl.Borrow.WithdrawRepay}</div>
            <div className='apy flex justify-between'>
                <div>{intl.Borrow.MyDebt}</div>
                {userinfo && <div>
                    <NumberText valueDecimals={18} displayDecimals={6} value={userinfo.borrowBalance} />
                    <span className='unit'>OORT</span>
                </div>}
            </div>
            <div className='balance flex items-center'>
                {intl.Borrow.WithdrawText1}
            </div>
            <div className='input-container display-amount flex items-center justify-between'>
                <div className='left'>
                    <div>OORT</div>
                    <div>{intl.Borrow.MainnetWallet}</div>
                </div>
                <div className='right'>
                    {userinfo && <NumberText value={userinfo.vaultAvailableBalance} valueDecimals={18} displayDecimals={6} />}
                </div>
            </div>
            <div className='balance flex items-center'>
                <span>{intl.Borrow.Willrepay}</span>
                {userinfo && <NumberText className='unit' value={userinfo.autoSplitRateMantiss} valueDecimals={16} displayDecimals={0} />}
                <span>%</span>
                <NumberText style={{ color: 'var(--theme-color)', marginInline: '8px' }} valueDecimals={18} displayDecimals={6} value={withdrawReapyAmount[0]} />
                <span className='unit'>OORT</span>
            </div>
            <div className='balance flex'>
                <span>{intl.Borrow.Willtransfer}</span>
                {userinfo && <NumberText className='unit' value={userinfo.autoTransferRateMantiss} valueDecimals={16} displayDecimals={0} />}
                <span>%</span>
                <NumberText style={{ color: 'var(--theme-color)', marginInline: '8px' }} valueDecimals={18} displayDecimals={6} value={withdrawReapyAmount[1]} />
                <span className='unit'>OORT</span>
            </div>
            <div className='action-group'>
                <button className='bg-primary full' onClick={handleWithdrawReapy}>{intl.Borrow.Confirm}</button>
            </div>
        </Drawer>

        <Drawer anchor='bottom' open={openRepay} onClose={reset}>
            <div className='title'>{intl.Borrow.Repay}</div>
            <div className='apy flex justify-between'>
                <div>{intl.Borrow.MyDebt} </div>
                {userinfo && <div>
                    <NumberText valueDecimals={18} displayDecimals={6} value={userinfo.borrowBalance} />
                    <span className='unit'>OORT</span>
                </div>}
            </div>
            <div className='balance'>
                {intl.Borrow.RepayText1}
            </div>
            <div className='input-container'>
                <InputNumber decimal={18} value={amount}
                    onChange={(e) => {
                        setAmount(e.target.value)
                        setMax(false)
                    }}
                    onMax={() => {
                        setMax(true);
                        setAmount(bignumber(amountMax).dividedBy(`1e+${18}`).toFixed(18))
                    }}
                />
            </div>
            <div className='balance flex'>
                <span>{intl.Borrow.WalletAvailableBalance}:</span>
                <NumberText style={{ color: 'var(--theme-color)', marginInline: '8px' }} valueDecimals={18} displayDecimals={6} value={walletBalance} />
                <span className='unit'>OORT</span>
            </div>
            <div className='action-group'>
                <button disabled={checkDisabled} className='bg-primary full' onClick={handleReapy}>{intl.Borrow.Confirm}</button>
            </div>
        </Drawer>
    </div>
}

export default BorrowPage