import { useContext, useEffect, useState } from 'react';
import NumberText from '../../components/NumberText';
import './index.scss'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CircularProgress, Drawer, Snackbar, TextField } from '@mui/material';
import { InputNumber } from '../../components';
import { copyText } from '../../utils';
import ReactDOM from 'react-dom/client';
import { useToast } from '../../utils/hooks';
import { Web3Context, useIntl } from '../../context';
import { getInviterCode, recordInviter } from '../../apis';
import { SiweMessage } from 'siwe';
import { CONSTANTS } from '../../models';
import { ContractTransactionResponse, ethers } from 'ethers';
import referralRewardContractABI from '../../assets/referral_reward.abi.json'
const ReferralPage = () => {
    const { intl } = useIntl();
    const { account, provider, setLoadingTX, refreshAccountInfo, refreshPoolInfo, userinfo, poolinfo } = useContext(Web3Context)
    const { showToast } = useToast();
    const [viewInviteCodeDrawer, setViewInviteCodeDrawer] = useState(false)
    const [enterInviteCodeDrawer, setEnterInviteCodeDrawer] = useState(false)
    const [inputCode, setInputCode] = useState('');
    const [bindinfo, setBindinfo] = useState<{ invitedByCode: string; invitationCode: string; reward: any; }>(null as any);
    const reset = () => {
        setViewInviteCodeDrawer(false)
        setEnterInviteCodeDrawer(false)
    }
    useEffect(() => {
        getinfo();
    }, [account])
    const getinfo = async () => {
        if (account) {
            const res = await getInviterCode(account);
            const cacheinvitationCode = localStorage.getItem('invitationCode');
            if (cacheinvitationCode && !res.invitedByCode && res.invitedByCode !== cacheinvitationCode) {
                setInputCode(cacheinvitationCode);
                setEnterInviteCodeDrawer(true)
            }
            setBindinfo(res);
        }
    }
    const bind = async () => {
        if (account) {
            try {
                const now = Date.now()
                const message = new SiweMessage({
                    // issuedAt: now.toString(),
                    domain: window.location.host,
                    address: account,
                    statement:
                        'You are signing this message to verify your ownership with the OORT Boost platform.' +
                        ' This signature is unique and does not grant any special permissions to any of the assets in your wallet.',
                    uri: window.location.origin,
                    version: '1',
                    chainId: +CONSTANTS.chainId,
                    nonce: now.toFixed(0),
                });
                const signres = await window.aleereum.sign(message.prepareMessage());
                if (signres && signres.success) {
                    const res = await recordInviter({
                        data: message.prepareMessage(),
                        signature: signres.msg,
                        address: account,
                        inviterCode: inputCode
                    });
                    if (res.error) {
                        setLoadingTX('failed', 'Binding <br/> failed')
                    } else {
                        setLoadingTX('successful', 'Binding <br/> successful')
                    }
                    getinfo();
                    reset();
                }
                console.log('signres', signres);
            } catch (error) {
                reset();
                console.log('error', error);
            }
        }
    }
    const claimReferral = async () => {
        if (account && provider) {
            try {
                setLoadingTX('pending');
                const inviterinfo = await getInviterCode(account);
                const signer = await provider.getSigner(account);
                const rewardContract = new ethers.Contract(CONSTANTS.referralRewardContractAddress, referralRewardContractABI, signer)

                let tx: ContractTransactionResponse = await rewardContract.claim(inviterinfo.reward.value, inviterinfo.reward.proof)
                await tx.wait();
                setLoadingTX('successful');
                getinfo();
                refreshAccountInfo();
                refreshPoolInfo();
                reset();
            } catch (error: any) {
                console.error('error', error);

                if (error.message && ((error.message as string).includes('user rejected action') || (error.message as string).includes('Cancel'))) {
                    setLoadingTX('cancel')
                } else {
                    setLoadingTX('failed')
                }
                reset()
            }
        }
    }

    return <div className='referral-page'>
        {userinfo && poolinfo ? <>
            <div className='card'>
                <div className='title'> {intl.Referral.HowReferralWorks}</div>
                <div className='text'>
                    {intl.Referral.Ruletext1}
                    <br />{intl.Referral.Ruletext2}</div>
                <div onClick={() => setViewInviteCodeDrawer(true)} className='flex items-center justify-center link'>{intl.Referral.MyInviteCode} <ArrowForwardIcon style={{ marginLeft: '8px' }} fontSize='small' /></div>
            </div>
            {bindinfo && !bindinfo.invitedByCode && <button onClick={() => {
                setEnterInviteCodeDrawer(true)
            }} className='bg-primary full' >{intl.Referral.EnterInviteCode}</button>}
            <div className='card'>
                <div className='card-info'>
                    <div className='title'>{intl.Referral.MyReferralRewards}</div>
                    <div className='value'>
                        <NumberText valueDecimals={18} value={bindinfo?.reward?.value || '0'} />
                        <span className='unit'>OORT</span>
                    </div>
                </div>
                <div style={{ paddingInline: '12px' }} className='flex'>
                    <button disabled={!bindinfo || bindinfo?.reward?.value === '0'} className='bg-white' onClick={claimReferral}>{intl.Referral.Claim}</button>
                </div>
            </div>
        </> : <div style={{ marginTop: '24px', textAlign: 'center' }}>
            <CircularProgress size={60} />
        </div>
        }
        <Drawer anchor='bottom' open={viewInviteCodeDrawer} onClose={reset}>
            <div className='title'>{intl.Referral.YourInviteCode}</div>
            <div className='input-container'>
                {bindinfo && <div className='invitation-code' >{bindinfo.invitationCode}</div>}
            </div>
            <div className='action-group'>
                <button disabled={false} className='bg-primary full' onClick={() => {
                    if (bindinfo && bindinfo.invitationCode) {
                        copyText(bindinfo.invitationCode, () => {
                            showToast(intl.Header.CopiedtoClipboard);
                            reset();
                        })
                    }
                }}>{intl.Referral.CopyCode}</button>
                <button disabled={false} className='bg-primary full' onClick={() => {
                    if (bindinfo && bindinfo.invitationCode) {
                        copyText(`${location.origin}/#/?invitationCode=${bindinfo.invitationCode}`, () => {
                            showToast(intl.Header.CopiedtoClipboard);
                            reset();
                        })
                    }
                }}>{intl.Referral.CopyLink}</button>
            </div>
        </Drawer >
        <Drawer anchor='bottom' open={enterInviteCodeDrawer} onClose={reset}>
            <div className='title'>{intl.Referral.PleaseEnteryourinvitationcode}</div>
            <div className='input-container'>
                <TextField className='invite-code' style={{ width: '100%', textAlign: 'center' }} value={inputCode}
                    onChange={(e) => {
                        setInputCode(e.target.value)
                    }} />
            </div>
            <div className='action-group'>
                <button disabled={!inputCode} className='bg-primary full' onClick={bind}>{intl.Borrow.Confirm}</button>
            </div>
        </Drawer>
    </div >
}

export default ReferralPage
