import { ContractTransaction, ContractEvent } from 'ethers';
import bignumber from 'bignumber.js'
export const shortStr = (str: string, count = 7) => {
    if (!str) {
        return ''
    }
    return `${str.substr(0, count)}...${str.substr(-(count - 2))}`
}
export function hexToBytes(hex: any) {
    let bytes = [];
    for (let c = 0; c < hex.length; c += 2)
        //@ts-ignore
        bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes;
}


export const handleTransaction = async (tx, successfulCb?: (tx) => void, failedCb?: Function) => {
    const res = await tx.wait()
    if (res.status == 1) {
        successfulCb && successfulCb(res);
    } else {
        failedCb && failedCb(res);
    }
    return
}

export const handleError = (error: Error) => {
    try {
        if (error && error.message && error.message.includes('revert reason: ')) {
            const temp = error.message.split('revert reason: ');
            if (temp[1]) {
                const revertCode = temp[1].split(', vm error: message')[0];
                console.log('revertCode', revertCode);
                console.log('error', error);
                return revertCode;
            }
        }
    } catch (error) {
        return ''
    }
}

export const bigdecimal = new bignumber('1000000000000000000');
export const NULLVAULT = '0x0000000000000000000000000000000000000000'
export const copyText = async (text: string, callback?: () => void) => {

    try {
        await navigator.clipboard.writeText(text);
        callback && callback()
        return
    } catch (error) {
        var textarea = document.createElement('textarea');
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        callback && callback();
        return
    }


}



export const formatBigNumberToNumber = (value: bignumber | string, valueDecimal = 18, decimal = 2) => {
    if (bignumber(value.toString()).isNegative()) {
        return 0
    }
    return bignumber(value.toString()).div(bignumber(Math.pow(10, valueDecimal))).decimalPlaces(decimal, bignumber.ROUND_DOWN).toNumber();
}


export const formatTime = (e: string, t = false, intl) => {
    if (!intl) {
        return
    }
    const o = Date.now()
        , i = new Date(e).valueOf();
    let r;
    let n: any;
    let s: any
    if (t ? r = Math.floor((i - o) / 1e3) : r = Math.floor((o - i) / 1e3),
        r > 60 * 60 * 24) {
        n = Math.floor(r / 86400);
        n = n > 1 ? n + ` ${intl.Common.days} ` : n + ` ${intl.Common.day} `;
        s = Math.floor(r % (3600 * 24) / 3600);
        s = s > 1 ? s + ` ${intl.Common.hrs} ` : s + ` ${intl.Common.hr} `;
        let a = n + s;
        return t || (a = a + ` ${intl.Common.ago} `),
            a
    } else if (r > 60 * 60) {
        n = Math.floor(r / 3600);
        n = n > 1 ? n + ` ${intl.Common.hrs} ` : n + ` ${intl.Common.hr} `;
        s = Math.floor(r % 3600 / 60);
        s = s > 1 ? s + ` ${intl.Common.mins} ` : s + ` ${intl.Common.min} `;
        let a = n + s;
        return t || (a = a + ` ${intl.Common.ago} `),
            a
    } else if (r > 60) {
        n = Math.floor(r / 60);
        n = n > 1 ? n + ` ${intl.Common.mins} ` : n + ` ${intl.Common.min} `;
        let s = n;
        return t || (s = s + ` ${intl.Common.ago} `),
            s
    } else {
        Math.max(r, 1);
        n = Math.max(r, 1);
        n = n > 1 ? n + " secs" : n + " sec";
        let s = n;
        return t || (s = s + ` ${intl.Common.ago} `),
            s
    }
}