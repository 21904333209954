import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Web3Provider } from './context';
import './polyfills';
import reportWebVitals from './reportWebVitals';

import { CONSTANTS } from './models';
import bignumber from 'bignumber.js'
import './index.scss';
import './customer.scss'

bignumber.config({
  ROUNDING_MODE: bignumber.ROUND_DOWN,
  FORMAT: {
    decimalSeparator: '',
    groupSeparator: '',
    groupSize: 3,
    secondaryGroupSize: 2,
  },
})

if (decodeURIComponent(location.search).includes('debug=1')) {
  const script = document.createElement('script');
  script.src = '//cdn.jsdelivr.net/npm/eruda';
  document.head.appendChild(script);
  script.onload = (e) => {
    console.log('script.onload', e);
    window.eruda.init()

  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.>
  <App />
  // </React.StrictMode>
  // <React.StrictMode>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
