import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ethers, JsonRpcProvider } from "ethers";
import { CONSTANTS, } from "../models";
import PlanetABI from '../assets/Planet.abi.json';
import SpaceControlABI from '../assets/SpaceControl.abi.json';
import INodeContractABI from '../assets/INode.abi.json'
import { CircularProgress, Dialog, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import bignumber from 'bignumber.js'
import { NULLVAULT } from "../utils";
import './index.scss'
import { useNavigate } from "react-router-dom";
import { IntlProvider, useIntl } from "./IntlContext";
import { HeaderProvider } from "./HeaderContext";
export interface Web3ContextData {
    isMobile: boolean;
    account: `0x${string}` | undefined;
    readonlySpaceControlContract: ethers.Contract;
    readonlyPlanetContract: ethers.Contract;
    spaceControlContract: ethers.Contract;
    planetContract: ethers.Contract;
    INodeContract: ethers.Contract;
    chainId: any;
    walletBalance: string;
    loadingTX: LoadingTX;
    poolinfo: Poolinfo;
    userinfo: Userinfo;
    leftNodeCreditSum: string;
    provider: ethers.BrowserProvider;
}
interface Poolinfo {
    totalStaking: string;
    totalBorrow: string;
    stakingAPY: string;
    borrowAPY: string;
    liquidity: string;
    utility: string;
    maxBorrowAmount: string;
    borrowAPYList: any[];
    stakingAPYList: any[][];
}
interface Userinfo {
    borrowBalance: string;
    myStaking: string;
    vault: string;
    mycredit: string;
    accountNodelist: string[];
    vaultNodelist: string[];
    vaultAvailableBalance: string;
    autoSplitRateMantiss: string;
    autoTransferRateMantiss: string;
    accountTotalCollateral: string;
    accountLockedRewards: string;
    vaultLockedRewards: string;
    vaultTotalCollateral: string;

}
interface LoadingTX {
    status: LoadingTXStatus;
    hash: string;
    message: string;
}
type LoadingTXStatus = '' | 'confirming' | 'pending' | 'successful' | 'failed' | 'cancel' | 'finished';


export interface Web3Context extends Web3ContextData {
    setWeb3: React.Dispatch<any>;
    disconnect: () => void;
    connectWallet: () => void;
    refreshPoolInfo: () => void;
    refreshAccountInfo: () => void;
    setLoadingTX: (status: LoadingTXStatus, message?: string) => void;
    setLeftNodeCreditSum: React.Dispatch<any>;
    removeToast: () => void;
    showToast: (text: string, timeout?: number) => void;
}



export const Web3Context = createContext<Web3Context>({} as Web3Context);

export const READONLYPROVIDER = new JsonRpcProvider("https://mainnet-rpc.oortech.com", "any");
const readonlySpaceControlContract = new ethers.Contract(CONSTANTS.SpaceControlContractAddress, SpaceControlABI, READONLYPROVIDER)
const readonlyPlanetContract = new ethers.Contract(CONSTANTS.PlanetContractAddress, PlanetABI, READONLYPROVIDER)
export const Web3Provider = ({ children }) => {
    const navigate = useNavigate();
    const { intl } = useIntl();
    const [_showToast, setShowToast] = useState(false)
    const [toastText, setToastText] = useState('')
    const [poolinfo, setPoolinfo] = useState<Poolinfo>(null as any);
    const [userinfo, setUserinfo] = useState<Userinfo>(null as any);
    const [web3Store, setWeb3] = useState<Web3ContextData>({} as any);
    const [walletBalance, setWalletBalance] = useState('0');
    const { account, chainId, spaceControlContract, planetContract, INodeContract, provider } = web3Store;
    const [isMobile, setIsMobile] = useState(false);
    const [leftNodeCreditSum, _setLeftNodeCreditSum] = useState('0')
    const [loadingTX, _setLoadingTX] = useState<LoadingTX>({
        status: '',
        hash: '',
        message: '',
    })

    useEffect(() => {
        window.onload = () => {
            console.log('   window.onload ');
            setTimeout(() => {
                connectWallet()

            }, 2000);
        }
    }, [])
    useEffect(() => {
        function watchWindowSize() {
            setIsMobile(document.documentElement.clientWidth < 840)
        }
        watchWindowSize()
        window.addEventListener("resize", watchWindowSize);
        return () => {
            window.removeEventListener("resize", watchWindowSize)
        }
    }, [])
    useEffect(() => {
        refreshPoolInfo();
    }, [])
    useEffect(() => {
        if (account) {
            refreshAccountInfo();
        }
    }, [account])
    const setLoadingTX = (status: LoadingTXStatus, message = '') => {
        _setLoadingTX(pre => {
            return {
                ...pre,
                status,
                message
            }
        })
    }
    const setLeftNodeCreditSum = (value) => {
        _setLeftNodeCreditSum(value)
    }

    const connectWallet = async () => {
        try {
            console.log('connectWallet');
            if (typeof window.aleereum !== 'undefined') {
                const provider = window['aleereum'];
                const ethereum = window.ethereum;
                if (provider) {
                    const isConnected = provider.isConnected;
                    const islocked = provider.islocked;
                    try {
                        if (!isConnected || islocked) {
                            await provider.connect();
                        }
                    } catch (error) {
                        console.error('aleereum connect error');
                    }
                    try {
                        provider.on("on_account_change", (account) => {
                            disconnect();
                            setTimeout(() => {
                                connectWallet()
                                navigate('/')
                            }, 10000);
                            console.log('aleereum on_account_change', account)
                        })
                    } catch (error) {
                        console.log('aleereum error', error);
                    }
                    try {
                        ethereum.on("on_account_change", (account) => {
                            disconnect();
                            console.log('ethereum on_account_change', account);
                            setTimeout(() => {
                                connectWallet();
                                navigate('/')
                            }, 10000);
                        })
                    } catch (error) {
                        console.log('ethereum error', error);
                    }

                    // if (!isConnected || islocked) {
                    //     await provider.connect();
                    //     setTimeout(() => {
                    //         connectWallet();
                    //     }, 1000);
                    //     return
                    // }
                    const account = provider.account;
                    const networkId: string = provider.networkId;
                    const oortProvider = new ethers.BrowserProvider(ethereum, Number("970"))
                    const signer = await oortProvider.getSigner(account);
                    const spaceControlContract = new ethers.Contract(CONSTANTS.SpaceControlContractAddress, SpaceControlABI, signer)
                    const planetContract = new ethers.Contract(CONSTANTS.PlanetContractAddress, PlanetABI, signer);
                    const INodeContract = new ethers.Contract(CONSTANTS.INodeContractAddress, INodeContractABI, signer);
                    const balance = await oortProvider.getBalance(account);
                    setWalletBalance(balance.toString());
                    setWeb3((pre) => ({
                        ...pre,
                        provider: oortProvider,
                        isConnected: true,
                        islocked: false,
                        spaceControlContract,
                        planetContract,
                        INodeContract,
                        account,
                        chainId: networkId
                    }))
                }
            }
        } catch (error) {
            console.log('error', error);

        }

    }

    const disconnect = () => {
        // _disconnect();
        setWeb3({} as any)
        setUserinfo(null as any);
        setPoolinfo(null as any);
    }

    const refreshPoolInfo = async () => {
        setPoolinfo(null as any);
        console.log('refreshPoolInfo');

        if (readonlyPlanetContract && readonlySpaceControlContract) {
            try {
                // const totalSupply: bigint = await readonlyPlanetContract.totalSupply()
                const totalBorrows: bigint = await readonlyPlanetContract.totalBorrows()
                const totalCash: bigint = await readonlyPlanetContract.getCash();
                const totalReserve: bigint = await readonlyPlanetContract.totalReserves();
                let maxBorrowAmount: bigint
                try {
                    maxBorrowAmount = await readonlySpaceControlContract.maxBorrowAmount.staticCall();

                } catch (error) {
                    maxBorrowAmount = BigInt(0)
                }
                // utili borrow staking  / seecond
                const [n1, n2, n3]: bigint[] = await readonlyPlanetContract.getAllRates();
                const rate = await readonlyPlanetContract.getInterestRateModelParams();

                const { baseRate, jumpMultiplier, kink, multiplier } = rate.toObject();
                const realkink = bignumber(kink.toString()).dividedBy(1e16);
                const coefficient = bignumber(3600 * 24 * 360);
                const zeroborrowapy = bignumber(baseRate.toString())
                const eightyborrowapy = (bignumber(multiplier.toString()).multipliedBy(0.8)).plus(zeroborrowapy);
                const fullborrowapy = (bignumber(jumpMultiplier).multipliedBy(0.2)).plus(eightyborrowapy);
                const borrowAPYList = new Array(101).fill(null).map((v, i) => {
                    if (i <= realkink.toNumber()) {
                        return [i, zeroborrowapy.plus(bignumber(multiplier).multipliedBy(i / 100)).multipliedBy(coefficient).dividedBy(1e14).toFixed(0)]
                    }
                    return [i, ((bignumber(jumpMultiplier).multipliedBy(bignumber((i - realkink.toNumber()) / 100))).plus(eightyborrowapy)).multipliedBy(coefficient).dividedBy(1e14).toFixed(0)];

                })
                const stakingAPYList = new Array(101).fill(null).map((v, i) => {
                    return [i, bignumber(borrowAPYList[i][1]).multipliedBy(bignumber(i / 100)).multipliedBy(bignumber(1 - 0.25)).toFixed(0)]
                });
                const liquidity = bignumber(totalCash.toString());
                setPoolinfo({
                    borrowAPYList,
                    stakingAPYList,
                    utility: n1.toString(),
                    totalStaking: (totalCash + totalBorrows - totalReserve).toString(),
                    totalBorrow: totalBorrows.toString(),
                    stakingAPY: bignumber(n3.toString()).multipliedBy(3600 * 24 * 360).toString(),
                    borrowAPY: bignumber(n2.toString()).multipliedBy(3600 * 24 * 360).toString(),
                    liquidity: liquidity.gt(0) ? liquidity.toFormat(0) : '0',
                    maxBorrowAmount: maxBorrowAmount.toString()
                })
            } catch (error) {
                console.log('refreshPoolInfo error', error);
            }
        }
    }
    const refreshAccountInfo = async () => {
        setUserinfo(null as any);
        console.log('refreshAccountInfo');
        if (provider && account) {
            const balance = await provider.getBalance(account);
            setWalletBalance(balance.toString());
        }
        if (planetContract && spaceControlContract && INodeContract && account) {
            const balanceOfUnderlying: bigint = await planetContract.balanceOfUnderlying.staticCall(account);
            const vault = await spaceControlContract.vaults(account)
            const accountNodelist = await INodeContract.getOwnerNodeList(account);
            const getNodeTypeInfo = await INodeContract.getNodeTypeInfo();

            const borrowBalanceCurrent: bigint = await planetContract.borrowBalanceCurrent.staticCall(account);

            const autoSplitRateMantiss: bigint = await spaceControlContract.autoSplitRateMantissa()
            const accountOwnerInfo: { [props: string]: bigint } = await INodeContract.ownerInfo(account);
            let vaultinfo
            if (vault !== NULLVAULT) {
                const vaultOwnerInfo: { [props: string]: bigint } = await INodeContract.ownerInfo(vault);
                const vaultNodelist = await INodeContract.getOwnerNodeList(vault);
                const [mycredit] = await spaceControlContract.evaluate(vault, vaultNodelist.toArray());
                const [vaultAvailableBalance] = await INodeContract.ownerAvailableBalanceAndPledge(vault);
                if (vaultNodelist.length > 0) {
                    const nodeinfo = await INodeContract.nodeInfo(vaultNodelist[0])
                }
                vaultinfo = {
                    vaultNodelist: vaultNodelist.toArray(),
                    mycredit: mycredit.toString(),
                    vaultAvailableBalance: vaultAvailableBalance.toString(),
                    vaultLockedRewards: vaultOwnerInfo.lockedRewards.toString(),
                    vaultTotalCollateral: vaultOwnerInfo.realPledge.toString(),
                } as Userinfo
            }
            setUserinfo({
                borrowBalance: borrowBalanceCurrent.toString(),
                myStaking: balanceOfUnderlying.toString(),
                vault,
                accountNodelist: accountNodelist.toArray(),
                autoSplitRateMantiss: autoSplitRateMantiss.toString(),
                autoTransferRateMantiss: bignumber(1e18).minus(bignumber(autoSplitRateMantiss.toString())).toString(),
                accountLockedRewards: accountOwnerInfo.lockedRewards.toString(),
                accountTotalCollateral: accountOwnerInfo.realPledge.toString(),
                ...vaultinfo
            } as Userinfo)
        }
    }
    const showToast = useCallback((text: string, timeout: number = 5000) => {
        if (_showToast) {
            removeToast();
            setTimeout(() => {
                showToast(text, timeout)
            }, 0);
        }
        setToastText(text);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false)
        }, 5000);
    }, [_showToast])
    const removeToast = useCallback(() => {
        if (_showToast) {
            setShowToast(false);
        }
    }, [_showToast])


    return (
        <IntlProvider>

            <Web3Context.Provider
                value={{
                    provider,
                    isMobile,
                    connectWallet,
                    account,
                    chainId,
                    setWeb3,
                    poolinfo,
                    userinfo,
                    disconnect,
                    readonlyPlanetContract,
                    readonlySpaceControlContract,
                    spaceControlContract,
                    planetContract,
                    INodeContract,
                    leftNodeCreditSum,
                    setLeftNodeCreditSum,
                    refreshPoolInfo,
                    refreshAccountInfo,
                    walletBalance,
                    loadingTX,
                    setLoadingTX,
                    showToast,
                    removeToast,
                }}
            >
                <HeaderProvider>
                    {children}
                </HeaderProvider>
                <Dialog
                    className="loading-dialog"
                    PaperProps={{
                        style: {
                            width: '60vw'
                        }
                    }}
                    open={loadingTX.status === 'pending' || loadingTX.status === 'confirming'}
                >
                    <DialogContent className="confirm-tx">
                        <CircularProgress size={60} />
                    </DialogContent>
                </Dialog>
                <Dialog
                    className="loading-dialog"
                    PaperProps={{
                        style: {
                            width: '60vw'
                        }
                    }}
                    open={loadingTX.status === 'successful'}
                    onClose={() => setLoadingTX('')}
                >
                    <DialogTitle className="dialog-title">
                        <div dangerouslySetInnerHTML={{ __html: loadingTX.message ? loadingTX.message : intl.Header.TransactionSuccess }}></div>
                    </DialogTitle>
                    <DialogContent className="confirm-tx">
                        <CheckCircleIcon style={{ fontSize: '60px', color: 'var(--theme-color)' }} />
                    </DialogContent>
                    <div style={{ marginBottom: '20px' }} className="flex justify-center">
                        <button onClick={() => setLoadingTX('finished')} className="bg-secondary">OK</button>
                    </div>
                </Dialog>
                <Dialog
                    className="loading-dialog"
                    PaperProps={{
                        style: {
                            width: '60vw'
                        }
                    }}
                    open={loadingTX.status === 'failed'}
                    onClose={() => setLoadingTX('')}
                >
                    <DialogTitle className="dialog-title" >
                        <div dangerouslySetInnerHTML={{ __html: loadingTX.message ? loadingTX.message : intl.Header.TransactionFailed }}></div>
                    </DialogTitle>
                    <DialogContent className="confirm-tx">
                        <SentimentVeryDissatisfiedIcon style={{ fontSize: '60px', color: '#E83434' }} />
                    </DialogContent>
                    <div style={{ marginBottom: '20px' }} className="flex justify-center">
                        <button onClick={() => setLoadingTX('finished')} className="bg-secondary">OK</button>
                    </div>
                </Dialog>
                <Dialog
                    className="loading-dialog"
                    PaperProps={{
                        style: {
                            width: '60vw'
                        }
                    }}
                    open={loadingTX.status === 'cancel'}
                    onClose={() => setLoadingTX('')}
                >
                    <DialogTitle className="dialog-title" >
                        <div dangerouslySetInnerHTML={{ __html: intl.Header.TransactionCancelled }}></div>
                    </DialogTitle>
                    <DialogContent className="confirm-tx">
                        <SentimentVeryDissatisfiedIcon style={{ fontSize: '60px', color: '#E83434' }} />
                    </DialogContent>
                </Dialog>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={_showToast}
                    onClose={() => setShowToast(false)}
                    message={toastText}
                />
            </Web3Context.Provider>
        </IntlProvider>
    );
}
