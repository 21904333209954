
import { Web3Context, useIntl } from '../../context';
import { useContext, useEffect, useMemo, useState } from 'react';
import NumberText from '../../components/NumberText';
import { CircularProgress, Drawer } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { NULLVAULT } from '../../utils';
import { ArrowRightAltOutlined } from '@mui/icons-material';
import { getNodeListStatus } from '../../apis';
import './index.scss'
import CreateVaultDrawer from '../../components/CreateVaultDrawer';


interface DeviceStatusList {
    offline_nodes: number
    online_nodes: number
    total_nodes: number
    zero_pledge_nodes: number
}

const MydevicesPage = () => {
    const { intl } = useIntl();
    const { spaceControlContract, userinfo, account, setLoadingTX, refreshPoolInfo, refreshAccountInfo } = useContext(Web3Context);
    const [status, setStatus] = useState(0)
    const [openStart, setOpenStart] = useState(false)
    const [accountDeviceStatusList, setAccountDeviceStatusList] = useState<DeviceStatusList>()
    const [vaultDeviceStatusList, setVaultDeviceStatusList] = useState<DeviceStatusList>()
    useEffect(() => {
        if (account && userinfo) {
            getNodeListStatus(account).then(res => {
                setAccountDeviceStatusList(res);
            })
            getNodeListStatus(userinfo.vault).then(res => {
                setVaultDeviceStatusList(res);
            })
        }
    }, [userinfo, account])

    const reset = () => {
        setOpenStart(false);
    }

    return <div className='mydevices-page'>
        {userinfo ? <>
            <div className='devices-status-tab-list flex'>
                <div onClick={() => setStatus(0)} className={`status-tab ${status === 0 ? 'active' : ''}`}>{intl.Device.InAccount}</div>
                {<div onClick={() => {
                    if (userinfo.vault == NULLVAULT || userinfo.vaultNodelist.length == 0) {
                        return
                    }
                    setStatus(1)
                }} className={`status-tab ${userinfo.vault == NULLVAULT || userinfo.vaultNodelist.length == 0 ? 'disabled' : ''} ${status == 1 ? 'active' : ''}`}>{intl.Device.Collateral}</div>}
            </div>
            {userinfo && status === 0 && <>
                <div className='card-list flex'>
                    <div className='card'>
                        <div className='card-info'>
                            <div className='title'>{intl.Device.DeviceNumber}</div>
                            <div style={{ fontWeight: '700' }} className='value'>
                                {userinfo.accountNodelist.length} / 64
                            </div>
                            {userinfo.accountNodelist.length > 0 && <NavLink to={'/devicelist/account'}>
                                <div className='detail flex items-center justify-between'>
                                    <span>{intl.Device.ViewDetails}</span>
                                    <ArrowRightAltOutlined style={{ fontSize: '18px' }} />
                                </div>
                            </NavLink>}

                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-info'>
                            <div className='title'>{intl.Device.DeviceStatus}</div>
                            <div className='device-status flex'>
                                <div className='point online'></div>
                                <span>{intl.Device.Online} </span>
                                <span style={{ fontWeight: '700' }}>{accountDeviceStatusList ? accountDeviceStatusList.online_nodes : 0} </span>
                            </div>
                            <div className='device-status flex'>
                                <div className='point offline'></div>
                                <span>{intl.Device.Offline} </span>
                                <span style={{ fontWeight: '700' }}>{accountDeviceStatusList ? accountDeviceStatusList.offline_nodes : 0} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-info'>
                        <div className='title'>{intl.Device.TotalCollateral}</div>
                        <div className='flex justify-between value'>
                            <NumberText value={userinfo.accountTotalCollateral} valueDecimals={18} />
                            <span>OORT</span>
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-info'>
                        <div className='title'>{intl.Device.LockedRewards}</div>
                        <div className='flex justify-between value'>
                            <NumberText value={userinfo.accountLockedRewards} valueDecimals={18} />
                            <span>OORT</span>
                        </div>
                    </div>
                </div>
            </>}
            {userinfo && status === 1 && <>
                <div className='card-list flex'>
                    <div className='card'>
                        <div className='card-info'>
                            <div className='title'>{intl.Device.DeviceNumber}</div>
                            <div style={{ fontWeight: '700' }} className='value'>
                                {userinfo.vaultNodelist.length} / 64
                            </div>
                            {userinfo.vaultNodelist.length > 0 && <NavLink to={'/devicelist/vault'}>
                                <div className='detail flex items-center justify-between'>
                                    <span>{intl.Device.ViewDetails}</span>
                                    <ArrowRightAltOutlined style={{ fontSize: '18px' }} />
                                </div>
                            </NavLink>}
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-info'>
                            <div className='title'>{intl.Device.DeviceStatus}</div>
                            <div className='device-status flex'>
                                <div className='point online'></div>
                                <span>{intl.Device.Online} </span>
                                <span style={{ fontWeight: '700' }}>{vaultDeviceStatusList ? vaultDeviceStatusList.online_nodes : 0} </span>
                            </div>
                            <div className='device-status flex'>
                                <div className='point offline'></div>
                                <span>{intl.Device.Offline} </span>
                                <span style={{ fontWeight: '700' }}>{vaultDeviceStatusList ? vaultDeviceStatusList.offline_nodes : 0} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-info'>
                        <div className='title'>{intl.Device.TotalCollateral}</div>
                        <div className='flex justify-between value'>
                            <NumberText value={userinfo.vaultTotalCollateral} valueDecimals={18} />
                            <span>OORT</span>
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-info'>
                        <div className='title'>{intl.Device.LockedRewards}</div>
                        <div className='flex justify-between value'>
                            <NumberText value={userinfo.vaultLockedRewards} valueDecimals={18} />
                            <span>OORT</span>
                        </div>
                    </div>
                </div>
            </>}
            {userinfo && userinfo.vault == NULLVAULT && <div className='btn-group'>
                <button className='bg-primary full' onClick={() => setOpenStart(true)} >{intl.Borrow.StartBorrow}</button>
            </div>}
            {userinfo && userinfo.vault !== NULLVAULT && <div className='btn-group'>
                {status === 0 && userinfo.accountNodelist.length > 0 && <NavLink to={'/devicetransfer/transfer'}>
                    <button className='bg-primary full'  >{intl.Header.TransferDevicetoCollateral} </button>
                </NavLink>}
                {status === 1 && userinfo.vault.length > 0 && <NavLink to={'/devicetransfer/back'}>
                    <button className='bg-primary full'  >{intl.Header.TransferDeviceBackfromCollateral}</button>
                </NavLink>}
            </div>}</>
            : <div style={{ marginTop: '24px', textAlign: 'center' }}>
                <CircularProgress size={60} />
            </div>}
        <CreateVaultDrawer open={openStart} onClose={reset} />

    </div >
}

export default MydevicesPage