import { useParams } from 'react-router'
import './index.scss'
import { useContext, useEffect, useMemo, useState } from 'react';
import { Web3Context, useHeader, useIntl } from '../../context';
import { getNodeList } from '../../apis';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { shortStr } from '../../utils';
import NumberText from '../../components/NumberText';
import { Checkbox, CircularProgress } from '@mui/material';
import bignumber from 'bignumber.js';
import { ContractTransactionResponse } from 'ethers';
const DeviceTransferPage = () => {
    const { intl } = useIntl();
    const {  selectAll, setSelectAll } = useHeader()
    const { account, userinfo, spaceControlContract, refreshAccountInfo, refreshPoolInfo, setLoadingTX, INodeContract, setLeftNodeCreditSum } = useContext(Web3Context)
    const { goal } = useParams<{ goal: 'transfer' | 'back' }>();
    const [creditList, setCreditList] = useState<Array<Array<any>>>([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const handle = async () => {
            try {
                setLoading(true)
                let list = [];
                let nodeList: string[] = []
                if (goal === 'transfer' && userinfo) {
                    nodeList = userinfo.accountNodelist;
                }
                if (goal === 'back' && userinfo) {
                    nodeList = userinfo.vaultNodelist;
                }
                if (spaceControlContract) {
                    list = await spaceControlContract.nodeCreditList(nodeList);
                    const temp: Array<Array<any>> = [];
                    nodeList.forEach((key, index) => {
                        temp.push([key, list[index], false])
                    })
                    setCreditList(temp)
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
            }
        }
        handle()
    }, [goal, userinfo, spaceControlContract])
    useEffect(() => {
        if (creditList.length > 0) {
            if (goal === 'back' && userinfo) {
                let leftNodeCreditSum = '0'
                creditList.forEach(credit => {
                    if (!credit[2]) {
                        leftNodeCreditSum = bignumber(leftNodeCreditSum).plus(bignumber(credit[1])).toString()
                    }
                })
                setLeftNodeCreditSum(leftNodeCreditSum)
                if (!bignumber(leftNodeCreditSum).minus(userinfo.borrowBalance).gte(0)) {
                    setError(true)
                    return
                }
                setError(false)
            }
            if (goal === 'transfer' && userinfo) {
                let selectedNodeCreditSum = '0'
                creditList.forEach(credit => {
                    if (credit[2]) {
                        selectedNodeCreditSum = bignumber(selectedNodeCreditSum).plus(bignumber(credit[1])).toString()
                    }
                })
                setLeftNodeCreditSum(selectedNodeCreditSum)
            }
            setError(false)
        }
    }, [creditList, userinfo, goal])
    useEffect(() => {
        setSelectAll(false);
    }, [])
    useEffect(() => {
        setCreditList((pre) => {
            pre.forEach(p => { p[2] = selectAll });
            return [...pre]
        })
    }, [selectAll])
    const filterdAddressList = useMemo(() => {
        return creditList.filter(credit => credit[2]).map(credit => credit[0])
    }, [creditList])

    const handleConfirm = async () => {
        try {
            setLoadingTX('pending');
            let tx: ContractTransactionResponse;
            if (goal === 'transfer' && userinfo && filterdAddressList.length > 0) {
                tx = await INodeContract.changeOwner(userinfo.vault, filterdAddressList)
            }
            if (goal === 'back' && userinfo && filterdAddressList.length > 0) {
                tx = await spaceControlContract.exit(filterdAddressList)
            }
            if (tx!) {
                await tx.wait();
                setLoadingTX('successful');
                setLeftNodeCreditSum('')
                refreshAccountInfo();
                refreshPoolInfo();
                console.log('tx complete', tx.hash);
            }
        } catch (error: any) {
            if (error.message && ((error.message as string).includes('user rejected action') || (error.message as string).includes('Cancel'))) {
                setLoadingTX('cancel')
            } else {
                setLoadingTX('failed')
            }
        }
    }

    return <div className='device-transfer-page'>
        {userinfo && !loading ? <>
            {creditList.map((credit, index) => <div key={index} className='card'>
                <div className='flex items-center'>
                    <LocationOnIcon style={{ color: '#FFA011' }} />
                    <span>{shortStr(credit[0])}</span>
                </div>
                <div className='flex'>
                    <span style={{ marginLeft: '24px' }}>{intl.Device.Credit} </span>
                    <NumberText style={{ fontWeight: '500' }} className='unit' value={credit[1]} valueDecimals={18} displayDecimals={2} />
                    <span className='unit'>OORT</span>
                </div>
                <Checkbox className='checkbox' onChange={(event) => {
                    setCreditList((pre) => {
                        pre[index][2] = event.target.checked;
                        return [...pre]
                    })
                }} value={true} checked={credit[2]} />
            </div>)}
            {creditList.length > 0 && <div className='btn-group'>
                {error && userinfo && <div className='error-text'>
                    <span> {intl.Device.WarningText1} </span>
                    <NumberText valueDecimals={18} displayDecimals={6} value={userinfo.borrowBalance} />
                    <span className='unit'>OORT</span>
                </div>}
                <button onClick={handleConfirm} disabled={error || filterdAddressList.length == 0} className={`bg-primary full ${error ? 'error' : ''}`}  >{intl.Borrow.Confirm}</button>
            </div>}
        </> : <div style={{ marginTop: '24px', textAlign: 'center' }}>
            <CircularProgress size={60} />
        </div>}
    </div>
}
export default DeviceTransferPage