
import './index.scss'
import { Web3Context, useIntl } from '../../context';
import { useContext, useEffect, useMemo, useState } from 'react';
import bignumber from 'bignumber.js';
import NumberText from '../../components/NumberText';
import Mask from '../../components/Mask';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import ReactDOMServer from 'react-dom/server';

const Dashboard = () => {
    const { intl } = useIntl();
    const { planetContract, poolinfo } = useContext(Web3Context)
    // const { stakingAPY, totalStaking, borrowAPY, totalBorrow } = poolinfo;
    useEffect(() => {
    }, [planetContract])

    const stakingAPYOption = useMemo(() => {
        if (poolinfo) {
            return {
                grid: {
                    left: '40px',
                    top: '50px'
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: false,
                    splitNumber: 5,
                    axisLabel: {
                        formatter: function (value) {
                            return `${value}%`
                        }
                    },
                },
                yAxis: [{
                    type: 'value',
                    max: '6000',
                    splitNumber: 5,
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        formatter: function (value) {
                            return `${value / 100}%`
                        }
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            type: "solid" // 实线，不写默认虚线
                        }
                    }
                },
                ],
                tooltip: {
                    trigger: 'axis',
                    formatter: (params: any[]) => {
                        return ReactDOMServer.renderToString(
                            <div style={{ fontSize: '12px' }}>
                                <div>
                                    {params?.[0]?.marker && <span dangerouslySetInnerHTML={{ __html: (params[0].marker as string).replace(params[0].color, '#9AA8CB') }}></span>}
                                    <span>{intl.Dashboard.Utilization}: {params[0].data[0]}%</span>
                                </div>
                                {params.map((data, index) => {
                                    return <div key={index}>
                                        <span dangerouslySetInnerHTML={{ __html: data.marker }}></span>
                                        <span>{data.seriesName}: {data.data[1] / 100}%</span>
                                    </div>
                                })}
                            </div>)
                    }
                },
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    left: 0,
                    data: [intl.Dashboard.StakingAPY, intl.Dashboard.BorrowingAPY],
                },
                series: [
                    {
                        yAxisIndex: 0,
                        name: intl.Dashboard.StakingAPY,
                        data: poolinfo.stakingAPYList,
                        type: 'line',
                        smooth: true,
                        itemStyle: {
                            opacity: 0,
                            color: "#EB5E01",
                            fontSize: 14,
                        },
                        lineStyle: {
                            color: "#EB5E01",
                            width: 2, // 0 的时候可以隐藏线
                            type: "solid" // 实线，不写默认虚线
                        },
                        markLine: {
                            symbol: 'none',
                            lineStyle: {
                                color: "#9AA8CB",
                                type: 'dashed',
                            },
                            data: [{ xAxis: bignumber(poolinfo.utility).div(1e16).toFixed(0, bignumber.ROUND_HALF_CEIL), label: { show: false }, }]
                        }
                    },
                    {
                        yAxisIndex: 0,
                        name: intl.Dashboard.BorrowingAPY,
                        data: poolinfo.borrowAPYList,
                        type: 'line',
                        smooth: true,
                        itemStyle: {
                            opacity: 0,
                            color: "#0058FF",
                            fontSize: 14,
                        },
                        lineStyle: {
                            color: "#0058FF",
                            width: 2, // 0 的时候可以隐藏线
                            type: "solid" // 实线，不写默认虚线
                        }
                    },
                ]
            }
        }
    }, [poolinfo, intl])
    return <div className='dashboard-page'>
        <div className='card'>
            <div className='title flex justify-between'>
                <div>{intl.Dashboard.TotalStaking}</div>
                <div className='flex'>
                    <div className='apy'>{intl.Dashboard.StakingAPY}</div>
                    {poolinfo
                        ? <>
                            <NumberText valueDecimals={16} value={poolinfo.stakingAPY} />
                            <span>%</span>
                        </>
                        : <Mask loading width='40px' height='17px' />}
                </div>
            </div>
            <div className='value flex items-center justify-between'>
                {poolinfo
                    ? <NumberText valueDecimals={18} value={poolinfo.totalStaking} />
                    : <Mask loading width='100px' height='28px' />}
                <span>OORT</span>
            </div>
        </div>
        <div className='card'>
            <div className='title flex justify-between'>
                <div>{intl.Dashboard.TotalBorrowing}</div>
                <div className='flex'>
                    <div className='apy'> {intl.Dashboard.BorrowingAPY}</div>
                    {poolinfo
                        ? <>
                            <NumberText valueDecimals={16} value={poolinfo.borrowAPY} />
                            <span>%</span>
                        </>
                        : <Mask loading width='40px' height='17px' />}
                </div>
            </div>
            <div className='value flex items-center justify-between'>
                {poolinfo
                    ? <NumberText valueDecimals={18} value={poolinfo.totalBorrow} />
                    : <Mask loading width='100px' height='28px' />}
                <span>OORT</span>
            </div>
        </div>
        {poolinfo && <div style={{ marginTop: '20px' }}>
            <ReactECharts style={{ width: '100%', height: '300px' }} option={stakingAPYOption} />
        </div>}
    </div>
}

export default Dashboard