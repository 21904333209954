import { Navigate, Route, Routes, } from 'react-router'
import { HashRouter } from 'react-router-dom';
import { Header } from './components';
import Dashboard from './pages/Dashboard';
import EarnPage from './pages/Earn';
import { useContext, useEffect } from 'react';
import { Web3Context, Web3Provider } from './context';
import BorrowPage from './pages/Borrow';
import MydevicesPage from './pages/Mydevices';
import DeviceListPage from './pages/DeviceList';
import DeviceTransferPage from './pages/DeviceTransfer';
import DeviceDetailPage from './pages/DeviceDetail';
import ReferralPage from './pages/Referral';

const App = () => {
  return (
    <HashRouter>
      <Web3Provider>
        <Header />
        <div className='page-container'>
          <Routes>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/earn' element={<EarnPage />} />
            <Route path='/borrow' element={<BorrowPage />} />
            <Route path='/mydevices' element={<MydevicesPage />} />
            <Route path='/referral' element={<ReferralPage />} />
            <Route path={'/devicelist/:from'} element={<DeviceListPage />} />
            <Route path={'/devicetransfer/:goal'} element={<DeviceTransferPage />} />
            <Route path={'/devicedetail/:from/:address'} element={<DeviceDetailPage />} />
            <Route path="*" element={<Navigate to='/dashboard' />} />
          </Routes>
        </div>
      </Web3Provider>
    </HashRouter>
  )
}

export default App
