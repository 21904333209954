export const a = 'god'
import Axios, { AxiosError, AxiosResponse } from 'axios';


Axios.interceptors.request.use((config) => {
    return config
})
Axios.interceptors.response.use((response) => {
    if (response.data && response.data.data) {
        return response.data.data
    }
    return response.data ?? '';
}, (error: AxiosError) => {
    // error.
    // console.log('error', error);
    if (error.response) {
        return error.response.data
    }
    return error
})


export const getNodeListStatus: (owner_address: string) => Promise<any> = (owner_address) => {
    return Axios.get<any>('https://mainnet-app.oortech.com/app/owner/info', {
        params: {
            owner_address,
        }
    })
}
export const getNodeList: (owner_address: string) => Promise<any> = (owner_address) => {
    return Axios.get<any>('https://mainnet-app.oortech.com/app/owner/nodes/list', {
        params: {
            owner_address,
            status: 'all',
            limit: '64',
            page: 1
        }
    })
}

export const getNodeInfo: (node_address: string) => Promise<any> = (node_address) => {
    return Axios.get<any>('https://mainnet-app.oortech.com/app/node/info', {
        params: {
            node_address,
        }
    })
}


export const getActivityList: (params: {
    node_address: string,
    page?: number
}) => Promise<any> = (params) => {
    return Axios.get<any>('https://mainnet-app.oortech.com/app/node/activity', {
        params: {
            ...params,
            limit: 50,
        }
    })
}

export const getInviterCode: (address: `0x${string}`) => Promise<any> = (address) => {
    return Axios.get(`/api/user/${address}`)
}

export const recordInviter: (data: {
    data: any,
    signature: string,
    address: string,
    inviterCode: string
}) => Promise<any> = (data) => {
    return Axios.post('/api/user/record_inviter', {
        ...data
    })
}

export const getReferralReward: (address: `0x${string}`) => Promise<any> = (address) => {
    return Axios.get(`/api/user/${address}`)
}