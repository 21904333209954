import { createContext, FC, useState, useMemo, useContext } from "react";
export const LANGMAP = {
    'EN': 'English',
    'ZH': '简体中文',
}
export const LANGJSON = {}
Object.keys(LANGMAP).forEach(key => {
    LANGJSON[key] = require(`../locales/${key}.json`);
})
const defaultLang: string = localStorage.getItem('lang')
    ? localStorage.getItem('lang')!
    : navigator.language.toLowerCase().includes('zh')
        ? 'ZH'
        : 'EN'
export const IntlContext = createContext<{
    intl: any;
    lang: string;
    setLang: React.Dispatch<string>;
}>((() => {
    return {
        intl: LANGJSON[defaultLang],
        lang: defaultLang,
        setLang: () => { }
    }
})())

export const IntlProvider: FC<any> = ({ children }) => {
    const [lang, _setLang] = useState(() => {
        return defaultLang
    })
    const setLang = (value: string) => {
        _setLang(value);
        localStorage.setItem('lang', value);
    }
    const intl = useMemo(() => {
        if (lang) {
            return LANGJSON[lang]
        }
        return LANGJSON[defaultLang]
    }, [lang])
    return <IntlContext.Provider value={{ intl, lang, setLang }}>
        {children}
    </IntlContext.Provider>
}

export const useIntl = () => {
    return useContext(IntlContext)
}