import React, { FC } from 'react'
import './index.scss'

interface MaskProps extends React.ComponentProps<any> {
    width?: string
    height: string;
    block?: boolean;
    loading?: boolean;
    style?: React.CSSProperties

}
const Mask: FC<MaskProps> = (props) => {
    const { width = '0px', height, block = false, loading = false, style = {} } = props;

    return <div className={`loader-mask ${loading ? 'loading' : ''}`} style={{
        width: block ? '100%' : width,
        height,
        ...style
    }} >&nbsp;</div>
}

export default Mask