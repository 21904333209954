import { useContext ,cloneElement,createElement} from "react"
import { Web3Context } from "../context"


export const useContract = () => {
    const { provider } = useContext(Web3Context);
    if (provider) {

    }
}

export const useToast = () => {
    const { showToast, removeToast } = useContext(Web3Context);

    return { showToast, removeToast }
}