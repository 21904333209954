import bignumber from 'bignumber.js';
import React, { useMemo } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface NumberTextProps extends Omit<NumericFormatProps, 'value'> {
  value?: bignumber | string;
  className?: string
  displayValue?: string
  displayMax?: number
  defaultIfNull?: string
  /**
   * Handle decimals for integers values
   * Example: value=100000 valueDecimals=2 => 1000.00
   * Default is 0, meaning display the value as it is
   */
  valueDecimals?: number
  displayDecimals?: number
  roundMode?: bignumber.RoundingMode
}

const NumberText: React.FC<NumberTextProps> = ({
  value,
  displayMax,
  defaultIfNull = '',
  valueDecimals = 0,
  displayDecimals = 2,
  roundMode = bignumber.ROUND_HALF_EVEN,
  ...others
}) => {
  const { result: formattedValue, prefix } = useMemo(() => {
    const resValue = new bignumber(value?.toString() || 0)
      .dividedBy(new bignumber(10).pow(valueDecimals))
      .decimalPlaces(displayDecimals, roundMode)
    const maxValue = new bignumber(displayMax || '0')

    let result = value ? resValue.toFixed(displayDecimals) : defaultIfNull
    if (displayMax && value && !resValue.isNaN() && resValue.gt(maxValue)) {
      result = `${displayMax}`
      others.prefix = '>'
    }

    return { result, prefix: others.prefix }
  }, [value, displayMax])
  if (formattedValue === defaultIfNull) {
    return (
      <span style={others.style} className={others.className}>
        {defaultIfNull}
        {defaultIfNull === 'N/A' ? '' : others.suffix || ''}
      </span>
    )
  }

  return (
    <NumericFormat
      thousandSeparator
      value={formattedValue}
      displayType="text"
      {...others}
      prefix={prefix}
    />
  )
}

export default NumberText
