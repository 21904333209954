import { useParams } from 'react-router'
import './index.scss'
import { useContext, useEffect, useState } from 'react';
import { Web3Context, useIntl } from '../../context';
import { getNodeList } from '../../apis';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { shortStr } from '../../utils';
import { CircularProgress } from '@mui/material';
import { NavLink } from 'react-router-dom';
const DeviceListPage = () => {
    const { intl } = useIntl();
    const { account, userinfo } = useContext(Web3Context)
    const { from } = useParams();
    const [nodeList, setNodeList] = useState<any[]>([])
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const handle = async () => {
            try {
                setLoading(true)
                if (from === 'account' && account) {
                    const { list } = await getNodeList(account);
                    setNodeList(list || [])
                }
                if (from === 'vault' && userinfo) {
                    const { list } = await getNodeList(userinfo.vault);
                    setNodeList(list || [])
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }

        }
        handle()
    }, [from, account, userinfo])
    return <div className='device-list-page'>
        {loading && <div style={{ marginTop: '24px', textAlign: 'center' }}>
            <CircularProgress size={60} />
        </div>}
        {!loading && nodeList.map((node, index) => <NavLink key={index} to={`/devicedetail/${from}/${node.node_address}`}>
            <div className='card'>
                <div className='flex items-center justify-between'>
                    <div className='flex items-center'>
                        <LocationOnIcon style={{ color: '#FFA011' }} />
                        <span>{shortStr(node.node_address)}</span>
                    </div>
                    <div className='flex items-center device-status'>
                        {node.client_status == 2 ? <>
                            <div className='point online'></div>
                            <span>{intl.Device.Online}  </span>
                        </> : <>
                            <div className='point offline'></div>
                            <span>{intl.Device.Offline} </span>
                        </>}
                    </div>
                </div>
                <div className='flex items-center justify-between'>
                    <div>{intl.Device.OnlineScore}: {node.online_score}</div>
                    <div className='day-reward'>
                        <span style={{ fontWeight: '500' }}>{(node.day_reward as number).toFixed(3)}</span>
                        <span className='unit'>OORT</span>
                    </div>
                </div>
            </div>
        </NavLink>
        )}
    </div>
}
export default DeviceListPage