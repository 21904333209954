import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { NavLink, useMatch, useParams, useNavigate } from "react-router-dom";
import { useLocation, useRoutes } from 'react-router'
import { Web3Context, useHeader, useIntl } from "../../context";
import { NULLVAULT, copyText, shortStr } from "../../utils";
import { CONSTANTS } from '../../models'
import './index.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NumberText from "../NumberText";
import bignumber from "bignumber.js";
import { Checkbox, Snackbar } from "@mui/material";
import { useToast } from "../../utils/hooks";
const Header = () => {
    const { account, isMobile, disconnect, chainId, connectWallet, userinfo, leftNodeCreditSum } = useContext(Web3Context);
    const { intl } = useIntl();
    const { selectAll, setSelectAll } = useHeader()
    const [networkStatus, setNetworkStatus] = useState(true);
    const devicelistMatch = useMatch('/devicelist/:from');
    const deviceTransferMatch = useMatch('/devicetransfer/:goal');
    const deviceDetailMatch = useMatch('/devicedetail/:from/:address');
    const referralMatch = useMatch('/referral');
    const navigate = useNavigate();
    const { showToast } = useToast();
    const loca = useLocation();

    useEffect(() => {
        if (chainId && +chainId != +CONSTANTS.chainId) {
            setNetworkStatus(false)
            return
        }
        setNetworkStatus(true)
    }, [chainId])
    const handleDisconnect = async () => {
        disconnect();
    }
    useEffect(() => {
        if (loca) {
            const searchParams = new URLSearchParams(loca.search);
            if (searchParams.get('invitationCode')) {
                localStorage.setItem('invitationCode', searchParams.get('invitationCode')!)
            }
        }
    }, [loca])

    const MenuRender = useCallback(() => {
        if (!!devicelistMatch) {
            return <div className="relative menu" onClick={() => navigate(-1)}>
                <div className="nav flex items-center">
                    <ArrowBackIcon style={{ fontSize: '18px', marginRight: '8px' }} />
                    <div>{devicelistMatch?.params?.from === 'account' ? intl.Header.MyDevicesinAccount : intl.Header.MyDevicesinCollateral}</div>
                </div>
            </div>
        }
        if (!!deviceTransferMatch) {
            return <div className="relative menu justify-between" >
                <div className="nav flex items-center" onClick={() => navigate(-1)}>
                    <ArrowBackIcon style={{ fontSize: '18px', marginRight: '8px' }} />
                    <div>{deviceTransferMatch?.params?.goal === 'transfer' ? intl.Header.TransferDevicetoCollateral : intl.Header.TransferDeviceBackfromCollateral}</div>
                </div>
                <div className="nav flex items-center">
                    <span>{intl.Header.Allselect}</span>
                    <Checkbox className='checkbox' onChange={(event) => {
                        setSelectAll(!selectAll);
                        // setCreditList((pre) => {
                        //     pre[index][2] = event.target.checked;
                        //     return [...pre]
                        // })
                    }} value={true} checked={selectAll} />
                </div>
            </div>
        }
        if (!!deviceDetailMatch) {
            return <div className="relative menu" onClick={() => navigate(-1)}>
                <div className="nav flex items-center">
                    <ArrowBackIcon style={{ fontSize: '18px', marginRight: '8px' }} />
                    <div>{deviceDetailMatch?.params?.from === 'account' ? intl.Header.DeviceDetailsinaccount : intl.Header.DeviceDetailsincollateral}</div>
                </div>
            </div>
        }
        if (!!referralMatch) {
            return <div className="relative menu" onClick={() => navigate(-1)}>
                <div className="nav flex items-center">
                    <ArrowBackIcon style={{ fontSize: '18px', marginRight: '8px' }} />
                    <div>{intl.Header.Referral}</div>
                </div>
            </div>
        }
        return <div className="relative menu">
            <NavLink className={({ isActive }) => `link ${isActive ? 'active' : ''}`} to={'/dashboard'}>{intl.Header.Dashboard}</NavLink>
            {account && <>
                <NavLink className={({ isActive }) => `link ${isActive ? 'active' : ''}`} to={'/earn'}>{intl.Header.Earn}</NavLink>
                <NavLink className={({ isActive }) => `link ${isActive ? 'active' : ''}`} to={'/borrow'}>{intl.Header.Borrow}</NavLink>
                <NavLink className={({ isActive }) => `link ${isActive ? 'active' : ''}`} to={'/mydevices'}>{intl.Header.MyDevices}</NavLink>
            </>}
        </div>
    }, [account, devicelistMatch, deviceTransferMatch, deviceDetailMatch, referralMatch, selectAll])

    const afterTransferCredit = useMemo(() => {
        if (userinfo && userinfo.mycredit && !!deviceTransferMatch) {
            if (deviceTransferMatch?.params?.goal === 'transfer') {
                return bignumber(userinfo.mycredit).plus(bignumber(leftNodeCreditSum)).toString()
            }
            return leftNodeCreditSum
        }
        return '0'
    }, [userinfo, leftNodeCreditSum, deviceTransferMatch])

    return <div className="header header-mobile">
        <div className="bg-container">
            <img className="bg" src="/bg.jpeg" alt="" />
            {!!deviceDetailMatch && <div className="container flex justify-between items-center">
                <div className="userinfo">
                    <div>{intl.Header.Device}</div>
                    <div onClick={() => copyText(deviceDetailMatch.params.address!, () => {
                        showToast(intl.Header.CopiedtoClipboard);
                    })} className="account flex items-center">
                        <img className="avatar" src="/icons/icon_avatar.png" alt="" />
                        <span style={{ marginInline: '12px' }}>{shortStr(deviceDetailMatch.params.address!)}</span>
                        <ContentCopyIcon style={{ fontSize: '14px' }} />
                    </div>
                </div>
            </div>}
            {!!deviceTransferMatch && <div className="container esitmate-credit">
                <div>{deviceTransferMatch?.params?.goal === 'transfer' ? intl.Header.EstimateCreditAfterTransfer : intl.Header.EstimateCreditAfterTransferBack} </div>
                <div className="flex value items-center">
                    <NumberText valueDecimals={18} value={afterTransferCredit} />
                    <span className="unit">OORT</span>
                </div>
            </div>}
            {!deviceTransferMatch && !deviceDetailMatch && <div className="container flex justify-between items-center">
                {!account ? <>
                    <div>{intl.Header.Welcome}</div>
                    <button onClick={connectWallet} className="bg-primary" >{intl.Header.ConnectWallet} </button>
                </> : <>
                    <div className="userinfo">
                        {userinfo && userinfo.vault != NULLVAULT && <>
                            <div>{intl.Header.MyVault}</div>
                            <div onClick={() => copyText(userinfo.vault, () => {
                                showToast(intl.Header.CopiedtoClipboard);
                            })} className="account flex items-center">
                                <img className="avatar" src="/icons/icon_avatar.png" alt="" />
                                <span style={{ marginInline: '12px' }}>{shortStr(userinfo.vault)}</span>
                                <ContentCopyIcon style={{ fontSize: '14px' }} />
                            </div>
                        </>}
                        <div>{intl.Header.Account}</div>
                        <div onClick={() => copyText(account, () => {
                            showToast(intl.Header.CopiedtoClipboard);
                        })} className="account flex items-center">
                            <img className="avatar" src="/icons/icon_avatar.png" alt="" />
                            <span style={{ marginInline: '12px' }}>{shortStr(account)}</span>
                            <ContentCopyIcon style={{ fontSize: '14px' }} />
                        </div>
                    </div>
                    {!referralMatch && <NavLink className='referral-link' to={'/referral'}><button className="bg-primary">{intl.Header.Referral}</button></NavLink>}
                </>
                }
            </div>}
        </div>
        <MenuRender />
    </div>
}
export default Header