import { TextField } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import './index.scss'
import bignumber from 'bignumber.js'
import { useIntl } from '../../context';
type InputProps = {
    value: string;
    onChange: (value) => void;
    maxText?: string;
    onMax?: (e) => void;
    validatorFunc?: any;
    decimal: number;
    // validatorText?: string;
}



const InputNumber: FC<InputProps> = (props) => {
    const { intl } = useIntl()
    const { value, onChange, onMax, maxText = intl.Header.All, decimal = 18, validatorFunc } = props;
    const [dirty, setDirty] = useState(false);
    const validatorIntenal = useCallback(() => {
        if (!validatorFunc) {
            return false
        }
        return validatorFunc(dirty) || false
    }, [value, dirty])
    return <div className={`cus-input ${!!onMax ? 'max-suf' : ''}`}>
        <TextField
            placeholder={'0.00'}
            autoComplete='off'
            fullWidth
            type="number"
            error={Boolean(validatorIntenal())}
            helperText={validatorIntenal() ? validatorIntenal() : ''}
            variant="filled"
            value={value}
            onChange={(e) => {
                const array = (e.target.value as string).split('.');
                if (array[1] && array[1].length > decimal) {
                    onChange({ target: { value: new bignumber(e.target.value).decimalPlaces(decimal, bignumber.ROUND_DOWN).toString() } });
                } else {
                    onChange(e);
                }
            }}
            onBlur={() => setDirty(true)}
        />
        {onMax && <div className='suffix max' onClick={onMax} dangerouslySetInnerHTML={{ __html: maxText }} />}
    </div>
}


export default InputNumber
