
import './index.scss'
import { CONSTANTS } from '../../models';
import { Web3Context, useIntl } from '../../context';
import { useContext, useEffect, useMemo, useState } from 'react';
import bignumber from 'bignumber.js';
import NumberText from '../../components/NumberText';
import { CircularProgress, Drawer } from '@mui/material';
import { InputNumber } from '../../components';
import { TransactionResponse } from 'ethers';
import { getInviterCode, recordInviter } from '../../apis';
const EarnPage = () => {
    const { intl } = useIntl();
    const { spaceControlContract, planetContract, setLoadingTX, refreshAccountInfo, refreshPoolInfo, walletBalance, userinfo, poolinfo, account } = useContext(Web3Context);
    const [openStake, setOpenStake] = useState(false)
    const [openUnstake, setOpenUnstake] = useState(false)
    const [amount, setAmount] = useState('')
    const [max, setMax] = useState(false);

    const amountMax = useMemo(() => {
        if (openStake) {
            return walletBalance
        }
        if (openUnstake && poolinfo && userinfo) {
            return bignumber(userinfo.myStaking).gt(poolinfo.liquidity) ? poolinfo.liquidity : userinfo.myStaking
        }
        return walletBalance
    }, [openStake, openUnstake, walletBalance, poolinfo, userinfo])
    const checkDisabled = useMemo(() => {
        if (!bignumber(amount).gt(0)) {
            return true
        }
        if (openStake) {
            if (bignumber(amount).multipliedBy(1e18).gt(walletBalance)) {
                return true
            }
        }
        if (openUnstake && userinfo && poolinfo) {
            if (bignumber(amount).multipliedBy(1e18).gt(userinfo.myStaking) || bignumber(amount).multipliedBy(1e18).gt(poolinfo.liquidity)) {
                return true
            }
        }
    }, [amount, openStake, walletBalance, userinfo, poolinfo])
    const handleStake = async () => {
        try {
            setLoadingTX('pending')
            const tx: TransactionResponse = await planetContract.mint('0', { value: bignumber(amount).multipliedBy(`1e+${18}`).toFormat(0) });
            await tx.wait()
            setLoadingTX('successful');
            refreshAccountInfo();
            refreshPoolInfo();
            reset()
        } catch (error: any) {
            console.error(error)
            console.error(error.message || 'error')
            if (error.message && ((error.message as string).includes('user rejected action') || (error.message as string).includes('Cancel'))) {
                setLoadingTX('cancel')
            } else {
                setLoadingTX('failed')
            }
            reset()
        }

    }
    const handleUnstake = async () => {
        try {
            setLoadingTX('pending')
            let tx: TransactionResponse
            if (max) {
                const balance: bigint = await planetContract.balanceOf.staticCall(account);
                tx = await planetContract.redeem(balance.toString())
            } else {
                tx = await planetContract.redeemUnderlying(bignumber(amount).multipliedBy(`1e+${18}`).toFormat(0))
            }
            await tx.wait()
            setLoadingTX('successful');
            refreshAccountInfo();
            refreshPoolInfo();
            reset()
        } catch (error: any) {
            console.error('error', error);
            if (error.message && ((error.message as string).includes('user rejected action') || (error.message as string).includes('Cancel'))) {
                setLoadingTX('cancel')
            } else {
                setLoadingTX('failed')
            }
            reset();
        }
    }
    const reset = () => {
        setAmount('');
        setMax(false);
        setOpenStake(false);
        setOpenUnstake(false)
    }

    return <div className='earn-page'>
        {userinfo && poolinfo ? <>
            <div className='card'>
                <div className='title flex justify-between'>
                    <div>{intl.Earn.MyStaking}</div>
                    <div className='flex'>
                        <div className='apy'>{intl.Dashboard.StakingAPY}</div>
                        {poolinfo && <NumberText valueDecimals={16} value={poolinfo.stakingAPY} />}
                        <span>%</span>
                    </div>
                </div>
                <div className='value flex items-center justify-between'>
                    {userinfo && <NumberText valueDecimals={18} value={userinfo.myStaking} />}
                    <span>OORT</span>
                </div>
            </div>
            <div className='card'>
                <div className='title flex justify-between'>
                    <div>{intl.Borrow.WalletAvailableBalance}</div>
                </div>
                <div className='value flex items-center justify-between'>
                    <NumberText valueDecimals={18} value={walletBalance} />
                    <span>OORT</span>
                </div>
            </div>
            <div className='btn-group'>
                <button className='bg-primary full' onClick={() => setOpenStake(true)} >{intl.Earn.Stake}</button>
                <button disabled={!bignumber(userinfo.myStaking).gt(0)} className='bg-secondary full' onClick={() => setOpenUnstake(true)} >{intl.Earn.Unstake}</button>
            </div>
        </> : <div style={{ marginTop: '24px', textAlign: 'center' }}>
            <CircularProgress size={60} />
        </div>}
        <Drawer anchor='bottom' open={openStake} onClose={reset}>
            <div className='title'>{intl.Earn.Stake}</div>
            <div className='apy flex justify-between'>
                <div>{intl.Dashboard.StakingAPY}</div>
                {poolinfo && <div>
                    <NumberText valueDecimals={16} value={poolinfo.stakingAPY} />
                    <span>%</span>
                </div>}
            </div>
            <div className='input-container'>
                <InputNumber decimal={18} value={amount}
                    onChange={(e) => {
                        setAmount(e.target.value)
                        setMax(false)
                    }}
                    onMax={() => {
                        setMax(true)
                        setAmount(bignumber(amountMax).dividedBy(`1e+${18}`).toFixed(18))
                    }}
                />
            </div>
            <div className='balance flex'>
                <span>{intl.Earn.Available}:</span>
                <NumberText style={{ color: 'var(--theme-color)', marginInline: '8px' }} valueDecimals={18} value={walletBalance} />
                <span>OORT</span>
            </div>
            <div className='action-group'>
                <button disabled={checkDisabled} className='bg-primary full' onClick={handleStake}>{intl.Earn.Stake}</button>
            </div>
        </Drawer>
        <Drawer anchor='bottom' open={openUnstake} onClose={reset}>
            <div className='title'>{intl.Earn.Unstake}</div>
            <div className='apy flex justify-between'>
                <div>{intl.Dashboard.StakingAPY}</div>
                {poolinfo && <div>
                    <NumberText valueDecimals={16} value={poolinfo.stakingAPY} />
                    <span>%</span>
                </div>}
            </div>
            <div className='input-container'>
                <InputNumber decimal={18} value={amount}
                    onChange={(e) => {
                        setAmount(e.target.value)
                        setMax(false)
                    }}
                    onMax={() => {
                        setMax(true)
                        setAmount(bignumber(amountMax).dividedBy(`1e+${18}`).toFixed(18))
                    }}
                />
            </div>
            <div className='balance flex'>
                <span> {intl.Earn.MyStaking}:</span>
                {userinfo && <NumberText style={{ color: 'var(--theme-color)', marginInline: '8px' }} valueDecimals={18} value={userinfo.myStaking} />}
                <span>OORT</span>
            </div>
            <div className='action-group'>
                <button disabled={checkDisabled} className='bg-primary full' onClick={handleUnstake}>{intl.Earn.Unstake}</button>
            </div>
        </Drawer>
    </div>
}

export default EarnPage