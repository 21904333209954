import { createContext, FC, useState, useContext } from "react";



export const HeaderContext = createContext<{
    onSelectAll: (boolean: boolean) => void;
    setOnSelectAll: React.Dispatch<(boolean: boolean) => void>;
    selectAll: boolean;
    setSelectAll: React.Dispatch<boolean>;

}>({} as any)

export const HeaderProvider: FC<any> = ({ children }) => {
    const [selectAll, setSelectAll] = useState(false);
    const [onSelectAll, setOnSelectAll] = useState(() => {
        return (boolean: boolean) => {
        }
    })
    return <HeaderContext.Provider value={{
        selectAll,
        setSelectAll,
        onSelectAll,
        setOnSelectAll
    }}>
        {children}
    </HeaderContext.Provider>
}
export const useHeader = () => {
    return useContext(HeaderContext)
}
