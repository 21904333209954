import { useParams } from 'react-router'
import './index.scss'
import { useContext, useEffect, useRef, useState } from 'react';
import { Web3Context, useIntl } from '../../context';
import { getActivityList, getNodeInfo, getNodeList } from '../../apis';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { formatTime, shortStr } from '../../utils';
import { Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import NumberText from '../../components/NumberText';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfiniteScroll from 'react-infinite-scroll-component';

const clientStatusMap = {
    '1': 'Offline',
    '2': 'Online'
};

const subTypeMap = {
    '1': {
        '1': 'NodeOffline',
        '2': 'NodeOnline',
        '3': 'AddedtoBlacklist',
        '4': 'RemovedfromBlacklist'
    },
    '2': {
        '1': 'NodeCollateral',
        '2': 'Reward',
        '3': 'Withdrawal',
        '4': 'RemoveCollateral',
        '5': 'Penalty'
    }

}

const DeviceDetailPage = () => {
    const { intl } = useIntl();
    const { account, userinfo, INodeContract } = useContext(Web3Context)
    const { from, address } = useParams();
    const [nodeinfo, setNodeinfo] = useState<any>(null)
    const [status, setStatus] = useState(0);
    const [activityList, setActivityList] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true); // 是否还有更多数据
    const scrollRef = useRef(null);
    const [rewardDialog, setRewardDialog] = useState(false)
    const [onlineDialog, setOnlineDialog] = useState(false)
    useEffect(() => {
        console.log('1', scrollRef);
        if (scrollRef.current) {
            // scrollRef.current.addEventListener('e')
        }
        return
    }, [scrollRef, status])
    useEffect(() => {
        const handle = async () => {
            if (address && INodeContract) {
                console.log('address', address);

                const nodeinfo = await getNodeInfo(address);
                const [availableBalance, pledge] = await INodeContract.nodeAvailableBalanceAndPledge(address);
                const res = await INodeContract.nodeAvailableBalanceAndPledge(address);
                setNodeinfo({
                    ...nodeinfo,
                    availableBalance: availableBalance.toString(),
                    pledge: pledge.toString()
                })
            }
        }
        handle()
    }, [address, INodeContract])
    useEffect(() => {
        if (address) {
            console.log('1', 1);
            fetchActivityLisy();
        }

    }, [address])
    const fetchActivityLisy = async () => {
        if (address) {
            const { list, count } = await getActivityList({ node_address: address, page })
            console.log('list', list);
            if ((page) * 50 > count) {
                setHasMore(false)
            }
            setActivityList((pre) => ([...pre, ...list]));
            setPage(page + 1)
        }
    }
    return <div className='device-detail-page'>
        <div className='devices-status-tab-list flex'>
            <div onClick={() => setStatus(0)} className={`status-tab ${status === 0 ? 'active' : ''}`}>{intl.Device.Overview}</div>
            {<div onClick={() => {
                setStatus(1)
            }} className={`status-tab ${status == 1 ? 'active' : ''}`}>{intl.Device.Activity}</div>}
        </div>
        {!nodeinfo && <div style={{ marginTop: '24px', textAlign: 'center' }}>
            <CircularProgress size={60} />
        </div>}
        {nodeinfo && <>
            {status === 0 && <>
                <div className='flex statistics-item-list'>
                    <div className='statistics-item'>
                        <div className='title'>{intl.Device.Status}</div>
                        <div className='amount flex items-center'>
                            {nodeinfo && <>
                                <div className={`point ${clientStatusMap[nodeinfo.client_status]} `}></div>
                                <span >{intl.Device[clientStatusMap[nodeinfo.client_status]]}</span>
                            </>}

                        </div>
                    </div>
                    <div className='statistics-item'>
                        <div className='title'>{intl.Device.LastHeartbeat}</div>
                        <div className='amount'>
                            {nodeinfo && <span>{formatTime(nodeinfo.last_heartbeat, false, intl)}</span>}
                        </div>
                    </div>
                </div>
                <div className='flex statistics-item-list'>
                    <div className='statistics-item'>
                        <div onClick={() => setRewardDialog(true)} className='title flex items-center'>
                            {intl.Device.RewardScore}
                            <HelpOutlineIcon style={{ marginLeft: '8px' }} fontSize='inherit' /></div>
                        <div className='amount'>
                            {nodeinfo && <span>{nodeinfo.reward_factor}</span>}
                        </div>
                    </div>
                    <div className='statistics-item'>
                        <div onClick={() => setOnlineDialog(true)} className='title flex items-center'>
                            {intl.Device.OnlineScore}
                            <HelpOutlineIcon style={{ marginLeft: '8px' }} fontSize='inherit' /></div>
                        <div className='amount'>
                            {nodeinfo && <span>{nodeinfo.online_score}</span>}
                        </div>
                    </div>
                </div>
                <div className='available'>
                    <div className='title'>{intl.Device.DeviceBalance}</div>
                    <div className='list-item flex justify-between items-center'>
                        <div className='coin-name flex items-center'>
                            <img src='icons/icon_oort.svg' />
                            <span>OORT</span>
                        </div>
                        {nodeinfo && <NumberText className='amount' value={nodeinfo.availableBalance} valueDecimals={18} displayDecimals={6} />}
                    </div>
                </div>
                <div className='total-collateral'>
                    <div className='title'>{intl.Device.Collateral2}</div>
                    <div className='list-item flex justify-between items-center'>
                        <div className='coin-name flex items-center'>
                            <img src='icons/icon_oort.svg' />
                            <span>OORT</span>
                        </div>
                        {nodeinfo && <NumberText className='amount' value={nodeinfo.pledge} valueDecimals={18} displayDecimals={0} />}
                    </div>
                </div></>}
            {status === 1 && <>
                <InfiniteScroll
                    className='activity-list'
                    dataLength={activityList.length}
                    next={() => { console.log('next'); fetchActivityLisy() }}
                    height={'calc(100vh - 235px)'}
                    loader={<h4 style={{ textAlign: 'center' }}>Loading</h4>}
                    endMessage={<p style={{ textAlign: 'center' }}>No more items to display</p>}
                    ref={scrollRef}
                    hasMore={hasMore}
                >
                    {activityList.map((activity, index) => <div key={index} className='item flex justify-between'>
                        <div className='left flex'>
                            <div>{intl.Device[subTypeMap[activity.type || '1'][activity.sub_type || '2']]}</div>
                            <div>{formatTime(activity.created_at, false, intl)}</div>
                        </div>
                        <div className='right flex items-center'>
                            {activity.type === 2 && (activity.sub_type == 1 || activity.sub_type == 2) && <>
                                <AddIcon style={{ color: '#FF718B' }} />
                                <span>{(activity.value as number).toFixed(6)}</span>
                                <span className='unit'>OORT</span>
                            </>}
                            {activity.type === 2 && (activity.sub_type == 3 || activity.sub_type == 5) && <>
                                <RemoveIcon style={{ color: '#52C41A' }} />
                                <span>{(activity.value as number).toFixed(6)}</span>
                                <span className='unit'>OORT</span>
                            </>}
                        </div>
                    </div>)}
                </InfiniteScroll>
            </>}
        </>}
        <Dialog
            className="confirm-dialog"
            onClick={() => setRewardDialog(false)}
            open={rewardDialog}>
            <div className='confirm-title' >{intl.Device.RewardScore}</div>
            <div>
                <div className='content1'>{intl.Device.RewardScoredesc1}</div>
                <div className='content2'>{intl.Device.RewardScoredesc2}</div>
            </div>
            <div><button onClick={() => setRewardDialog(false)} className='bg-white block'>{intl.Device.Close}</button></div>
        </Dialog>
        <Dialog
            className="confirm-dialog"
            onClick={() => setOnlineDialog(false)}
            open={onlineDialog}>
            <div className='confirm-title' >{intl.Device.OnlineScore}</div>
            <div>
                <div className='content1'>{intl.Device.OnlineScoredesc1}</div>
                <div className='content2'>{intl.Device.OnlineScoredesc2}</div>
            </div>
            <div><button onClick={() => setOnlineDialog(false)} className='bg-white block'>{intl.Device.Close}</button></div>
        </Dialog>
    </div>
}
export default DeviceDetailPage