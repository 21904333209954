import { Drawer } from "@mui/material"
import { Web3Context, useIntl } from "../../context";
import { FC, useContext } from "react";

type CreateVaultDrawerProps = {
    open: boolean;
    onClose: () => void;
}

const CreateVaultDrawer: FC<CreateVaultDrawerProps> = (props) => {
    const { intl } = useIntl();
    const { spaceControlContract, planetContract, setLoadingTX, refreshAccountInfo, refreshPoolInfo, walletBalance, userinfo, poolinfo, account, readonlySpaceControlContract, } = useContext(Web3Context);
    const { open, onClose } = props;
    const createVault = async () => {
        try {
            setLoadingTX('pending')
            const tx = await spaceControlContract.createVault();
            await tx.wait()
            setLoadingTX('successful');
            refreshAccountInfo();
            refreshPoolInfo();
            onClose()
        } catch (error: any) {
            console.error('error', error);
            if (error.message && ((error.message as string).includes('user rejected action') || (error.message as string).includes('Cancel'))) {
                setLoadingTX('cancel')
            } else {
                setLoadingTX('failed')
            }
            onClose();
        }
    }
    return <Drawer anchor='bottom' open={open} onClose={onClose}>
        <div className='title'>{intl.Borrow.CreateyourVault}</div>
        <div className='apy'>{intl.Borrow.CreateyourVaultText} </div>
        <div className='action-group'>
            <button className='bg-primary full' onClick={createVault}>{intl.Borrow.Confirm}</button>
        </div>
    </Drawer>
}


export default CreateVaultDrawer